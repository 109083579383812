import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTools } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Hero from "../../../components/hero"
import Section from "../../../components/section/section"
import ProductList from "../../../components/guide/product-list"
import AffiliateAlert from "../../../components/article/affiliate-alert"
import SuccessAlert from "../../../components/article/success-alert"

const Wrapper = styled.div`
    ${tw`container flex flex-col items-center`}
`

const Container = styled.div`
    ${tw`max-w-4xl`}
`

const CardContainerWrapper = styled.div`
${tw`container flex flex-wrap mx-auto text-center max-w-4xl`}
`

const Title = styled.p`
${tw`text-lg md:text-lg lg:text-xl font-semibold pt-3 leading-snug text-gray-900`}
`

const CardWrapper = styled.div`
    ${tw`w-full md:w-1/2 lg:w-1/3 p-2`}
`

const CardContainer = styled.div`
    ${tw`container flex flex-col mx-auto text-center bg-gray-600 p-2 md:p-4 rounded-lg`}
`

const TripodsStyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-psb-green-600 text-4xl md:text-5xl self-center`}
`

const StyledLink = styled(Link)`
    ${tw`text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold leading-snug pt-4`}
`

const TripodsStyledLink = styled(Link)`
    ${tw`block mx-4 p-2 rounded-lg text-white text-lg font-bold bg-psb-green-600`}
`

const SectionImageRight = styled.div`
    ${tw`hidden md:block md:w-1/4 float-right m-2`}
`

const Separator = styled.hr`
    ${tw`w-48 my-6 rounded-md border-2 border-psb-orange-400`}
`

const TripodGuidePage = ({ data, location }) => {
    const topPortableTripods = data.top_portable_tripods.edges

  	return (
    	<Layout>
        	<SEO
            	title="Pocket Shutterbug Tripod Guide"
	            pathname={location.pathname}
    	    />
			<Hero title="Tripod Guide" description="" />
        	<Section>
                <Wrapper>
                    <Container>
                        <p><Link to="/guides/">Guides</Link> - <strong>Tripods</strong></p>
                        <Separator />
                        <p>Welcome to the Pocket Shutterbug Tripod guide.</p>
                        <p>Read on below for our top tripod recommendations or jump straight to one of the following categories.</p>
                        <CardContainerWrapper>
                            <CardWrapper>
                                <CardContainer>
                                    <TripodsStyledFontAwesomeIcon icon={faTools} />
                                    <Title>Portable Tripods</Title>
                                    <p/>
                                    <TripodsStyledLink to="/guides/tripods/portable-tripods/">Explore</TripodsStyledLink>
                                </CardContainer>
                            </CardWrapper>
                            <CardWrapper>
                                <CardContainer>
                                    <TripodsStyledFontAwesomeIcon icon={faTools} />
                                    <Title>Travel Tripods</Title>
                                    <p/>
                                    <TripodsStyledLink to="/guides/tripods/">Coming soon...</TripodsStyledLink>
                                </CardContainer>
                            </CardWrapper>
                        </CardContainerWrapper>
                        <AffiliateAlert />
                        <StyledLink to="/guides/tripods/portable-tripods/">Our Portable Tripod Recommendations</StyledLink>
                        <p>Portable tripods are great to carry in your pocket or backpack when you are out and about, and help you get that smooth video shot, a group photo or for vlogging.</p>
                        <ProductList products={topPortableTripods} />
                        <h2>How we review and rate tripods</h2>
                        <p>We try to maintain consistency across our tripod review. Therefore we use the same set of criteria for each review.</p>
                        <h3>Features</h3>
                        <p>Not all tripods are created equal. Whilst most offer the basic ability to steady your camera to help you take more stable photos or videos, some include extra features that can enhance this experience.</p>
                        <h3>Build Quality</h3>
                        <p>Tripods can be made from a number of different materials, from cheap and light plastics, lightweight aluminium to strong carbon fiber.</p>
                        <h3>Stability</h3>
                        <p>The sole purpose of a tripod is to provide stability for your camera, whether you are shooting a landscape long exposure or taking video. Many cheaper tripods are simply not made well enough, especially the leg joints, to be able to hold steady a camera.</p>
                        <h3>Portability</h3>
                        <p>Whilst more important when looking at portable and travel tripods, it is still important to consider how easy the tripod can be carried and packed in luggage.</p>
                        <h3>Value</h3>
                        <p>Is an expensive carbon fibre tripod really worth the investment or are you better with a cheaper alumnium tripod.</p>
                    </Container>
                </Wrapper>
 	       </Section>
	    </Layout>
	)
}

export default TripodGuidePage

export const query = graphql`
    fragment Products on ProductsJson {
        productType
        name
        slug
        merchantURL
        merchant
        summary
        score
        categories
    }
    query {
        top_portable_tripods: allProductsJson(
            sort: { fields: [score], order: DESC }
            filter: { productType: { in: ["tripods"] }, categories: { in: ["Portable Tripods"] }, published: { eq: true } }
            limit: 3
        ) {
            edges {
                node {
                  ...Products
                }
            }
        }
    }
`